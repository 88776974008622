export const  message = {
  langto: 'EN',
  language:'Change Language To: ',
  cfmdefault: '请先选择默认应用',
  st_h0: '注册用户数',
  st_h1: '充值用户数',
  st_h2: '活跃用户数',
  st_h3: '注册设备数[单交直]',
  st_h4: '在线设备数[单交直]',
  st_h5: '平台用电量[单交直]',
  st_h6: '累计充值额(元)',
  st_h7: '上月充值额(元)',
  st_h8: '本月充值额(元)',
  st_h9: '今日充值额(元)',
  st_10: '累计退款额(元)',
  st_11: '累计赠送金额(元)',
  st_12: '资金账户余额(元)',
  st_13: '累计免收金额(元)',
  st_14: '提现分成额(元)',
  st_15: '单车总时长(分钟)',
  st_16: '插件版本号',
  st_17: '应用创始日',
  btn_ok:'确定',
  btn_back:'返回',
  btn_save:'保存',
  btn_cancel:'取消',
  btn_nextstep:'下一步',
  btn_prevpage:'上一页',
  btn_nextpage:'下一页',
  btn_uall:'全部',
  btn_ublack:'黑名单',
  btn_unormal:'普通',
  btn_uvip:'VIP',
  btn_umgr:'运营商',
  btn_search:'查找',
  btn_backhome:'返回首页',
  btn_chargerecod:'充值记录',
  btn_refundrecod:'退款记录',
  btn_user_visit:'',
  btn_pymnew:'新人',
  btn_pymcashed:'正式',
  btn_feecardsms:'派发',
  btn_feecardme:'输入手机派发',
  btn_iccardfp1:'分配',
  btn_iccardfp2:'输入手机分配',
  btn_fetchmore: '加载更多',
  cbk0:'单车',
  cbk1:'交流',
  cbk2:'直流',
  superadmin:'超级管理员',
  entermobile:'输入完整手机号',
  nickormobil:'手机号或昵称',
  login_title:'凭密进入管理后台',
  login_mobile:'管理员手机号',
  login_passwd:'登陆密码',
  login_save_pwd: '记住密码(请勿在公共场所使用)',
  login_text:'进入后台',
  login_btn_resetpw:'重置密码',
  login_btn_join:'申请加入',
  login_btn_help:'帮助说明',
  login_start:'如何开始合作',
  login_begin:'参考下面的图示打开充电小程序，点击进入运营助手，在运营助手中 <b>申请网页后台账号</b>。<br />',
  login_st1:'<b>充电运营助手：</b>创建充电站，扫码添加充电桩，查看实时数据。个人版管理工具。',
  login_st2:'<b>微信支付商家助手：</b>腾讯公司提供的管钱工具，运营商专属支付通道，T+0实时到账。',
  login_st3:'<b>网页后台管理：</b>主要负责大数据量的统计和展示，独立账户，运营上线申请开通。',
  login_st4:'<b>充电小程序：</b>运营商专属应用，自主品牌，自由扩展，免费开源，微信扫码打开。',
  login_st5:'立即致电 <a href="tel:4001009230"><b>4001009230</b></a> 讨论合作细节',
  reset_msg0:'管理者手机号(小程序-运营助手-填写手机号)',
  reset_msg1:'这是密码设置的唯一入口，请用复杂密码并妥善保管。',
  reset_msg2:'发送短信验证码',
  reset_msg3:'提供验证码，修改密码(仅限网页后台登陆用)',
  reset_msg4:'手机短信验证码',
  reset_msg5:'输入新密码',
  reset_msg6:'再次输入新密码',
  reset_msg7:'立即设置新密码',
  reset_err0:'手机号长度不对',
  reset_err1:'手机号中有非数字字符',
  reset_err2:'短信已发送至手机，请查收。',
  reset_err3:'请填写新密码并保存。',
  reset_err4:'请填写手机短信验证码',
  reset_err5:'新密码太短，最少6位',
  reset_err6:'两次密码输入不一致，请重新输入',
  reset_err7:'密码已经重置成功，请用新密码登陆。',
  site_name:'充电站名称',
  site_creator:'创建人',
  site_start:'启用日期<br/>(地图位置)',
  site_tocharger:'访问电桩列表<br/>单车/交流/直流',
  site_topaybill:'点击访问<br/>充电支付流水',
  site_fee1:'收费标准<br/><span class="small">电费+运营费</span>',
  site_fee2:'单车充电桩收费标准(功率:瓦)<br/>〜150~250~350~500~700〜',
  site_fee3:'单车<br/>最低',
  site_evday:'日统计<br/>',
  site_count:'日抄表',
  site_tt0: '在运营助手中修改',
  site_tt1: '点击可显示地图位置',
  site_tt2: '汽车充电定价标准',
  site_tt3: '单车充电最低收费(0~150瓦)',
  comm_bk1:'单',
  comm_bk2:'车',
  comm_ac1:'交',
  comm_ac2:'流',
  comm_dc1:'直',
  comm_dc2:'流',
  comm_cnt1:'统',
  comm_cnt2:'计',
  comm_cnt3:'电',
  comm_cnt4:'表',
  mnu_site:'电站',
  mnu_devices:'设备管理',
  mnu_mgrch:'管理',
  mnu_device0:'单车充电桩',
  mnu_device1:'汽车交流桩',
  mnu_device2:'直流快充桩',
  mnu_device3:'库存单车桩',
  mnu_device4:'库存交流桩',
  mnu_device5:'库存直流桩',
  mnu_iccard:'客户充电卡',
  mnu_user:'用户',
  mnu_prepay:'充值记录',
  mnu_money:'资金管理',
  mnu_svcbillset:'优惠券设置',
  mnu_svcbilluse:'优惠券使用',
  mnu_bill:'充电记录',
  mnu_bkbill:'单车充电记录',
  mnu_acbill:'汽车充电记录',
  mnu_dcbill:'直流充电记录',
  mnu_more:'更多业务',
  mnu_param:'平台参数配置',
  mnu_cnt:'运营数据日统计',
  mnu_vmoney:'虚拟充值卡',
  mnu_free:'免费充电用户',
  mnu_oper:'客户访问日志',
  mnu_cooper:'个人运营商管理',
  mnu_coopfee:'个人运营商提现',
  mnu_app:'应用管理',
  mnu_mgr:'授权管理',
  mnu_sto:'库存管理',
  mnu_beep:'设备心跳',
  mnu_maps:'电站地图',
  mnu_mystore:'采购库存',
  mnu_onlines:'在线电桩',
  app_appname:'应用名',
  app_version:'<版本>',
  app_charge:'充值',
  app_coopmoney:'运营提现',
  app_refund:'退',
  app_refunmsg:'允许客户自主退还账户余额',
  app_totaluser:'总用户数',
  app_chargeuser:'充电人数',
  app_today:'今日',
  app_month:'本月',
  app_premonth:'上月充值',
  app_sumpow:'累计用电',
  app_defapp0:'默认',
  app_defapp1:'应用',
  app_defapp2:'设为',
  app_defapp3:'清除',
  app_defapp4:'当前',
  app_balance:'资金池',
  app_mod0:'微信小程序appid',
  app_mod1:'微信小程序密钥',
  app_modg:'微信支付API密钥',
  app_mod2:'微信小程序名字',
  app_mod3:'微信支付商户号',
  app_mod4:'用来生成二维码',
  app_modh:'独立商户号必填。子商户号不需要填写。独立商户为客户自己申请，非小牛公司下属商户，此时商户号为红色。',
  app_mod5:'2~8个字',
  app_mod6:'请先申请',
  app_mod7:'保存设置',
  app_moda:'单车有效号码段',
  app_modb:'设备开机分配的编号段,多个段以逗号间隔,3或4个数字,第三个数字必需是偶数',
  app_modc:'交流有效号码段',
  app_modd:'电桩开机分配的编号段,多个段以逗号间隔,3或4个数字,第三个数字必需是奇数',
  app_mode:'快充有效号码段',
  app_modf:'直流快充桩有效号码段(多段时,最后一个段为当前工作段)',
  app_hover0:'点击名称可修改应用属性,默认按创建时间先后排序',
  app_hover1:'充电APP-绑手机-右上角 可见到充电软件插件版本 点击可切换版本/编号/商户号',
  app_hover2:'是否允许运营商APP充值',
  app_hover3:'个人运营者从本APP（子运营商）提现分成总金额',
  app_hover4:'总注册用户数，新客户注册时更新，可能存在偏差',
  app_hover5:'充电用户数，只要曾经充值就算',
  app_hover6:'今天的充值金额,点击可排序',
  app_hover7:'本月充值金额',
  app_hover8:'上月充值金额,点击可排序',
  app_hover9:'清除默认应用在某些功能中可以查所有数据',
  app_hovera:'大部份功能需要指定应用，必需要设置默认应用才能显示数据',
  yyma_th0:'所属应用',
  yyma_th1:'申请人微信昵称',
  yyma_th2:'手机号(是否设密)',
  yyma_th3:'真实姓名',
  yyma_th4:'申请时间',
  yyma_th5:'管理应用',
  yyma_th6:'免费',
  yyma_th7:'结算率',
  yyma_hover0:'申请者通过哪个应用申请',
  yyma_hover1:'同一客户可管理多个应用',
  yyma_hover2:'可以有多个相同手机号',
  yyma_hover3:'通过运营助手填写',
  yyma_hover4:'申请时间',
  yyma_hover5:'后台审批后允许管理的应用,可删除',
  yyma_hover6:'是否允许设置免费充电客户',
  yyma_hover7:'个人运营商提现分成比例,点击修改,小于1',
  pym_th0:'真实姓名',
  pym_th1:'注册时间',
  pym_th2:'手机号/头像/昵称',
  pym_th3:'电站(电桩)数',
  pym_th4:'分成',
  pym_th5:'可结算',
  pym_th6:'已提现',
  pym_th7:'申请时间',
  pym_th8:'余额',
  pym_th9:'充值',
  pym_tha:'消费',
  usr_th0:'用户手机',
  usr_th1:'余额',
  usr_th2:'最近访问',
  usr_th3:'注册日期',
  usr_th4:'访问来源',
  usr_th5:'充电次数',
  usr_th7:'充值',
  usr_th8:'赠送',
  usr_th6:'微信昵称(头像可放大)',
  usr_tha:'一号多充',
  usr_thb:'断电电流',
  usr_prepay_none: '-',
  usr_bad: '单/交/直',
  usr_loi: '充满不停(充电器空载电流很大,调大一点)/没充满自动停(充电器充电功率很小,调小一点)/调整范围:0,0.08~0.21/0表示完全关闭小电流检测',
  usr_alo: '同一账户允许同时充电车辆数目',
  usr_black: '黑名单用户无法启动充电',
  uop_th0: '手机(小屏分行显示)',
  uop_th1: '访问时间',
  uop_th2: '网络',
  uop_th3: '访问功能',
  uop_th4: '来源/昵称',
  uop_th5: '执行操作(可点击)',
  uop_th6: '微信用户(可人工绑定手机号)',
  pow_th0: '抄表日期',
  pow_th1: '抄表电量',
  pow_th2: '充电桩',
  pow_th3: '充电站',
  pow_th4: '每日用电抄表',
  msto_tt0: '不同APP之间不能直接变更编号，如有需要请先删除，再开机。',
  msto_tt1: '设备最后启动时间（离线时间）',
  msto_tt2: '运营助手扫码添加',
  msto_tt3: '分配到本人创建电站',
  msto_tt4: '运营助手取消分配才可以重新扫码',
  msto_ttf: '删除分配删除管理才可以重新扫码,点击切换显示电表数据',
  msto_ttg: '修改电表数据,单位千瓦时',
  msto_tt5: '联网方式,LTE可显示ICCID',
  msto_tt6: '设备固件版本',
  msto_tt7: '汽车为2端口,单车为12端口,可任意禁用',
  msto_tt8: '设备上粘贴的小程序码(二维码),可另存打印',
  msto_tt9: '设备开机次数',
  msto_tta: '所属应用(点击进入校表功能)',
  msto_ttb: '仅大屏幕',
  msto_ttc: '出厂前确定的唯一编号,与二维码对应,点击可查看详情',
  msto_ttd: '更多操作请横屏',
  msto_tte: '点击可修改,调高需审核,调0为禁用',
  msto_th0: '桩编号',
  msto_th9: '次数',
  msto_th1: '重启时间',
  msto_th2: '管理者(删)',
  msto_th3: '分配电站(删)',
  msto_th4: '分配日期(限流)',
  msto_thf: '分配日期(电表)',
  msto_thg: '当前电表读数,点击可修改',
  msto_th5: '网络',
  msto_th6: '固件',
  msto_th7: '端口',
  msto_th8: '操作',
  msto_tha: '所属应用',
  msto_thb: '---超级用户设备管理---',
  msto_thc: '离线时间',
  msto_thd: '重启原因',
  msto_the: '设置每个端口最大允许电流[单位安]',
  msto_ca: '证书',
  msto_fw: '升级',
  msto_getp: '数据',
  msto_getl: '日志',
  asto_btn_selectapp: '选择应用',
  asto_newdeviceidbk: '新设备编号(单车)',
  asto_newdeviceidac: '新设备编号(交流)',
  asto_newdeviceiddc: '新设备编号(快充)',
  rech_th0: '客户手机号',
  rech_th1: '到账日期',
  rech_th2: '到账时间',
  rech_th3: '退款时间',
  rech_th4: '请求时间',
  rech_th5: '充值¥',
  rech_th6: '退¥',
  rech_th7: '余额¥',
  rech_th8: '#',
  rech_th9: '微信昵称与头像',
  rech_tt6: '本次退款金额(账户余额与本次充值金额的最小值)',
  rech_tt7: '本次充值结束后的个人账户余额(含赠送)',
  rech_tt8: '用户资金变动情况序号',
  prz_pkwh: '元每度',
  prz_pmin: '每分钟',
  prz_phor: '每小时',
  prz_t00: '充电站定价收费标准',
  prz_tt0: '谷期电价',
  prz_tt1: '平期电价',
  prz_tt2: '峰期电价',
  prz_tt3: '服务费率',
  prz_tt4: '超时占位',
  prz_tt5: '电站名称',
  prz_tt6: '000~150瓦',
  prz_tt7: '150~250瓦',
  prz_tt8: '250~350瓦',
  prz_tt9: '350~500瓦',
  prz_t10: '500~700瓦',
  prz_t11: '700~900瓦',
  prz_t12: '谷期用电价格(汽车充电)',
  prz_t13: '如果不区分峰平谷，则可以将三个价格设为一样(汽车充电)',
  prz_t14: '如有需要，请重新定义谷平峰时间范围(汽车充电)',
  prz_t15: '充电客户可以看到两个价格：电价和服务费，此价格会显示给客户',
  prz_t16: '充电结束后长时间不离开，则可能产生超时占位费',
  prz_t17: '充电站名字,长度限制为八个字',
  prz_t18: '单车充电功率0~150瓦的价格标准',
  prz_t19: '单车充电功率150~250瓦的价格标准',
  prz_t20: '单车充电功率250~350瓦的价格标准',
  prz_t21: '功率越大，危险会增加，尽量避免高功率充电',
  prz_t22: '通过更高的单价来防止太多人用高功率充电器',
  prz_t23: '设备会进行总功率检查，总功率超高会限制充电',
  yyd_theday: '统计日期',
  yyd_devcnts: '电桩',
  yyd_bigch: '充电',
  yyd_psns: '人数',
  yyd_times: '次数',
  yyd_bigff: '消费',
  yyd_money: '金额',
  yyd_pow: '电表',
  yyd_powst: '电量',
  yyd_powused: '用量',
  yyd_cash: '提现日期',

  ref0:'未知情况',
  ref1:'供电超低',
  ref2:'软狗复位',
  ref3:'硬狗复位',
  ref4:'软件复位',
  ref5:'重新通电',
  ref6:'引脚复位',
  ref7:'供电异常',

  login_msg: 'aaaa'
}