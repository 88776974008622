
    export const APPVERSION = '3.0.1';
    
    // 后台API部署地址
    export const BASE = 'https://e.mosf.cn/webmgr';
    //export const BASE = 'http://127.0.0.1:3000';

    //存放二维码的URL地址
    export const QRSITE = 'https://a.mosf.cn';
    
