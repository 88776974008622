export const  message = {
  langto: '中文',
  language:'切换语言至: ',
  cfmdefault: 'Plz CONFIRM default app first',
  st_h0: 'RegUsers',
  st_h1: 'VipUsers',
  st_h2: 'Actives',
  st_h3: 'RegDevices[BkAcDc]',
  st_h4: 'OlsDevices[BkAcDc]',
  st_h5: 'PowersUsed[BkAcDc]',
  st_h6: 'TotReCharg(CNY)',
  st_h7: 'PreMReChar(CNY)',
  st_h8: 'TheMReChar(CNY)',
  st_h9: 'TodaReChar(CNY)',
  st_10: 'TotRetChar(CNY',
  st_11: 'TotGiftCha(CNY)',
  st_12: 'TotalFunds(CNY)',
  st_13: 'TotFreeMoy(CNY)',
  st_14: 'TotDividMy(CNY)',
  st_15: 'BKTotTimes(Min)',
  st_16: 'Plugin Ver',
  st_17: 'APPStarted',
  btn_ok:'OK',
  btn_back:'Back',
  btn_save:'Save',
  btn_cancel:'CANCEL',
  btn_nextstep:'NEXT',
  btn_prevpage:'Prev',
  btn_nextpage:'Next',
  btn_uall:'ALL',
  btn_ublack:'BLACK',
  btn_unormal:'Normal',
  btn_uvip:'VIP',
  btn_umgr:'Coop',
  btn_search:'Search',
  btn_pymnew:'New Cooper',
  btn_pymcashed:'Cooping',
  btn_feecardsms:'SendSMS',
  btn_feecardme:'Enter Phone Number',
  btn_iccardfp1:'ASIGN',
  btn_iccardfp2:'Enter Phone Number',
  btn_fetchmore: 'More...',
  cbk0:'Bike',
  cbk1:'ACev',
  cbk2:'Dcev',
  entermobile:'mobile number',
  nickormobil:'mobile or nickName',
  btn_backhome:'BACK HOME',
  btn_chargerecod:'ReCharge',
  btn_refundrecod:'Refund',
  btn_user_visit:'',
  login_title:'Login to WebConsole',
  login_mobile:'Login Mobile Phone',
  login_passwd:'Login Password',
  login_save_pwd: 'SavePassword(not in public env)',
  login_text:'Login',
  login_btn_resetpw:'LoginPass Reset',
  login_btn_join:'Join',
  login_btn_help:'Help',
  login_start:'How to Start',
  login_begin:'open charger mini wechat app and open assist function, then request <b>web console account</b>.<br />',
  login_st1:'<b>Operate assist: </b>a tool for create site, scan to add device, for personal use.',
  login_st2:'<b>Wepay assist: </b>a pay tool from tencent, money management',
  login_st3:'<b>Web Console: </b>this site, datas, sites, devices management.',
  login_st4:'<b>mini program: </b>a WeChat mini program, a Charger APP',
  login_st5:'Call <a href="tel:4001009230"><b>4001009230</b></a> for cooperation',
  reset_msg0:'Manager\'s mobile phone',
  reset_msg1:'This is the only way to reset password, plz use strong password.',
  reset_msg2:'Send SMS Verify Code',
  reset_msg3:'Fill in recieved SMS Code and set password',
  reset_msg4:'Verify Code in SMS',
  reset_msg5:'New Password',
  reset_msg6:'ReEnter New Password',
  reset_msg7:'Set Login Password Now',
  reset_err0:'The length of mobile phone ERROR',
  reset_err1:'Invalid cell phone number',
  reset_err2:'SMS message has been send, plz read the message.',
  reset_err3:'Plz set new password and save.',
  reset_err4:'Plz fill in verify code.',
  reset_err5:'new password too short, more than 6 chars needed.',
  reset_err6:'Two password not same, plz try again',
  reset_err7:'Password set successful, plz login use new password.',
  site_name:'Site Name',
  site_creator:'Creator',
  site_start:'Startdate<br/>(position)',
  site_tocharger:'Devices<br/>Bike/AC/DC',
  site_topaybill:'Click To<br/>Pay Bills',
  site_fee1:'Fees<br/><span class="small">elec+svc</span>',
  site_fee2:'Bike Power Fee(unit:wat)<br/>~150~250~350~500~700~',
  site_fee3:'Bik<br/>Low',
  site_evday:'byday cnt<br/>',
  site_count:'power use',
  site_tt0: 'Modify in Assist APP',
  site_tt1: 'Click for map Location',
  site_tt2: 'Prize for ev charger',
  site_tt3: 'minPrize bk (less150wat)',
  comm_bk1:'Bi',
  comm_bk2:'KE',
  comm_ac1:'AC',
  comm_ac2:'ev',
  comm_dc1:'DC',
  comm_dc2:'ev',
  comm_cnt1:'CN',
  comm_cnt2:'Ter',
  comm_cnt3:'PO',
  comm_cnt4:'Wer',
  mnu_site:'Sites',
  mnu_devices:'Devices',
  mnu_mgrch:'',
  mnu_device0:'Bike Charger',
  mnu_device1:'ACev Charger',
  mnu_device2:'DCev Charger',
  mnu_device3:'StoredBiker',
  mnu_device4:'Stored ACev',
  mnu_device5:'Stored DCev',
  mnu_iccard:'ICCards',
  mnu_user:'Users',
  mnu_prepay:'PrePays',
  mnu_money:'Moneys',
  mnu_svcbillset:'CouponSet',
  mnu_svcbilluse:'CouponUse',
  mnu_bill:'PayBills',
  mnu_bkbill:'BK Bills',
  mnu_acbill:'AC Bills',
  mnu_dcbill:'DC Bills',
  mnu_more:'More',
  mnu_param:'Param Setup',
  mnu_cnt:'Oper CNT per day',
  mnu_vmoney:'Virtual Cards',
  mnu_free:'Free Users',
  mnu_oper:'User Opers',
  mnu_cooper:'Personal Coop',
  mnu_coopfee:'Cooper Cash',
  mnu_app:'Apps',
  mnu_mgr:'Masters',
  mnu_sto:'Stores',
  mnu_beep:'TikTok',
  mnu_maps:'SitMap',
  mnu_mystore:'My Stores',
  mnu_onlines:'Online Devices',
  app_appname:'appname',
  app_version:'VER',
  app_charge:'ReCh',
  app_coopmoney:'coopcash',
  app_refund:'ret',
  app_refunmsg:'allow return balance by user',
  app_totaluser:'Users',
  app_chargeuser:'ChUs',
  app_today:'Today',
  app_month:'Month',
  app_premonth:'PMonth',
  app_sumpow:'sumpow',
  app_balance:'psnbalance',
  app_defapp0:'Dft',
  app_defapp1:'APP',
  app_defapp2:'Set',
  app_defapp3:'CLN',
  app_defapp4:'Cur',
  app_mod0:'wechat mini program appid',
  app_mod1:'mini program AppSecret Key',
  app_modg:'tencent pay api Key',
  app_mod2:'mini program app name',
  app_mod3:'wepay cooper id',
  app_mod4:'used for qrcode generat',
  app_modh:'used for standalone wepay',
  app_mod5:'2~8 characters',
  app_mod6:'plz request first',
  app_mod7:'Save Sets',
  app_moda:'New DeviceID (bk)',
  app_modb:'The DeviceID generat rule for bk, 3 or 4 digits, even number for 3rd needed ',
  app_modc:'New DeviceID (ac)',
  app_modd:'The DeviceID generat rule for ac, 3 or 4 digits, odd number for 3rd needed ',
  app_mode:'New DeviceID (dc)',
  app_modf:'The DeviceID generat rule for dc, (split by "," for multi parts, last for working)',
  app_hover0:'Click name to modify parameters.',
  app_hover1:'Charger APP - BindPhone - TOPRIGHT, Pluginversion is showed',
  app_hover2:'Sub Charger APP ReCharge Money Permission (global)',
  app_hover3:'total money dived by personal cooper',
  app_hover4:'Total registered users, update when new user found, maybe deviation to real',
  app_hover5:'Total charger users, update at first recharge',
  app_hover6:'Recharge money of today, click to order',
  app_hover7:'Recharge money of this month(today excluded)',
  app_hover8:'Recharge money of last month, click to order',
  app_hover9:'Clean Default APP can show full data in some functions',
  app_hovera:'Default APP must be set in some functions',
  yyma_th0:'Application',
  yyma_th1:'Requestor nickname',
  yyma_th2:'Mob(haspass)',
  yyma_th3:'RealName',
  yyma_th4:'Request',
  yyma_th5:'TheAPP',
  yyma_th6:'Free',
  yyma_th7:'Rate',
  yyma_hover0:'The APP requested by',
  yyma_hover1:'Multi apps allowed by one',
  yyma_hover2:'Multi same mobile allowed',
  yyma_hover3:'applied in operate assistant',
  yyma_hover4:'request time',
  yyma_hover5:'Permited APP, can be removed',
  yyma_hover6:'Free User can be added in web console',
  yyma_hover7:'Personal Operator cash rate, click to set, less than 1',
  pym_th0:'RealName',
  pym_th1:'RegTime',
  pym_th2:'Mobile/Head/NickName',
  pym_th3:'Site[Chargers]',
  pym_th4:'YYRate',
  pym_th5:'NeedPay',
  pym_th6:'Cashed',
  pym_th7:'UpdTime',
  pym_th8:'Balance',
  pym_th9:'Recharge',
  pym_tha:'Payed',
  usr_th0:'Mobile',
  usr_th1:'Balce',
  usr_th2:'Visit Time',
  usr_th3:'RegTime',
  usr_th4:'ComeFrom',
  usr_th5:'ChgTMS',
  usr_th7:'ReCh',
  usr_th8:'Gift',
  usr_th6:'NickName(avatar enlargable)',
  usr_tha:'MultiCharging',
  usr_thb:'OffCurrent',
  usr_prepay_none: '-',
  usr_bad: 'BK/AC/DC',
  usr_loi: 'change to lower or higher',
  usr_alo: 'allow chargings in same time',
  usr_black:'BLACK user can not start charging',
  uop_th0: 'PhoneType(hide on small size)',
  uop_th1: 'Visti Time',
  uop_th2: 'Net',
  uop_th3: 'Function',
  uop_th4: 'VisitIP',
  uop_th5: 'Operation(Clickable)',
  uop_th6: 'WeChartUser(can bind mobile)',
  pow_th0: 'Day',
  pow_th1: 'Pow',
  pow_th2: 'Charger#',
  pow_th3: 'Site#',
  pow_th4: 'PowerUsed',
  msto_tt0: 'DONT change id number in diffrent app, if needed, remove first',
  msto_tt1: 'Last PowerOn or Offline time',
  msto_tt2: 'Added by QRScan in assist APP',
  msto_tt3: 'Apply to manager\'s site',
  msto_tt4: 'Remove manager/siteid by assist',
  msto_ttf: 'Remove first, power data showed by click',
  msto_ttg: 'Modify power, KWh',
  msto_tt5: 'Network type, iccid can be show in lte',
  msto_tt6: 'Device firmware version',
  msto_tt7: '2ports for ev, can be disabled',
  msto_tt8: 'qrcode showed on device, can be download',
  msto_tt9: 'Device PowerON times',
  msto_tta: 'TheApp(click to power verify)',
  msto_ttb: 'BigScreenOnly',
  msto_ttc: 'DeviceNumber, genrate before sale, click for detail',
  msto_ttd: 'Landscape for More function',
  msto_tte: 'Currency saved',
  msto_th0: 'DevID',
  msto_th9: 'Cnt',
  msto_th1: 'POWTm',
  msto_th2: 'Manager(DEL)',
  msto_th3: 'Siteid(DEL)',
  msto_th4: 'AplyDate(iMax)',
  msto_thf: 'AplyDate(Power)',
  msto_thg: 'Current Power, Changable',
  msto_th5: 'NET',
  msto_th6: 'VER',
  msto_th7: 'Ports',
  msto_th8: 'Opers',
  msto_tha: 'TheApp',
  msto_thb: '---SuperManagerTools----',
  msto_thc: 'OfflineTM',
  msto_the: 'MaxLimitedCurrency(Amp)',
  msto_ca: 'CA',
  msto_fw: 'FW',
  msto_getp: 'DAT',
  msto_getl: 'LOG',
  asto_btn_selectapp: 'SELECTAPP',
  asto_newdeviceidbk: 'newDEVID For bk',
  asto_newdeviceidac: 'newDEVID For ac',
  asto_newdeviceiddc: 'newDEVID For dc',
  rech_th0: 'CellPhone',
  rech_th1: 'RegTime',
  rech_th2: 'PayTime',
  rech_th3: 'ReFndTime',
  rech_th4: 'ReqTime',
  rech_th5: 'Rec¥',
  rech_th6: 'Ret¥',
  rech_th7: 'Balance',
  rech_th8: '#',
  rech_th9: 'WechartNickname&headimg',
  rech_tt6: 'Return Fee Value (less than balance and chargemount)',
  rech_tt7: 'The Balance after this chargement',
  rech_tt8: 'Fund Change SeqNo',
  prz_pkwh: 'CNYpkwh',
  prz_pmin: 'CNYpmin',
  prz_phor: 'CNYphor',
  prz_t00: 'Prize Setup for sites',
  prz_tt0: 'LowPrize',
  prz_tt1: 'MidPrize',
  prz_tt2: 'Hi Prize',
  prz_tt3: 'ServcFee',
  prz_tt4: 'TmoutFee',
  prz_tt5: 'SiteName',
  prz_tt6: '000~150',
  prz_tt7: '150~250',
  prz_tt8: '250~350',
  prz_tt9: '350~500',
  prz_t10: '500~700',
  prz_t11: '700~900',
  prz_t12: 'Lower Prize(evcharger)',
  prz_t13: 'You can set to same without Low/Mid/Hi(evcharger)',
  prz_t14: 'If you want you can refine param here(evcharger)',
  prz_t15: 'Custom will show power prize and servicefee',
  prz_t16: 'Timeout Fee maybe cause after finishing charger withou leave',
  prz_t17: 'The sitename less than 8 chars',
  prz_t18: 'Biker charger prize at 0~150 wat',
  prz_t19: 'Biker charger prize at 150~250 wat',
  prz_t20: 'Biker charger prize at 250~350 wat',
  prz_t21: 'Danger at hi power, try to avoid hi power charge',
  prz_t22: 'To avoid hi power charge on hi prize',
  prz_t23: 'Total limited by device, not more than 3.6 kw',
  yyd_theday: 'theday',
  yyd_devcnts: 'devs',
  yyd_bigch: 'chg ',
  yyd_psns: 'psns',
  yyd_times: 'times',
  yyd_bigff: 'chg ',
  yyd_money: 'money',
  yyd_powst: 'pow ',
  yyd_powst: 'start',
  yyd_powuse: 'used',
  yyd_cash: 'cashdate',
  
  ref0:'UNKNOWN',
  ref1:'LOW_PWR',
  ref2:'SFWWDOG',
  ref3:'HDIWDOG',
  ref4:'SORESET',
  ref5:'POWERON',
  ref6:'MCU_PIN',
  ref7:'BROWNOU',
  
  login_msg: 'aaa'
}